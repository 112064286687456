"use strict";

var ShoppingCart = function () {

    var addToCartUrl = "/umbraco/Cart/ShoppingCartSurface/AddToCart/";
    var getCartItemsUrl = "/umbraco/Cart/ShoppingCartSurface/GetCartItems/";

    var cartItems;

    // Public function that gets current cart
    var getCartItems = function getCartItems() {
        $.ajax({
            type: "GET",
            cache: false,
            url: getCartItemsUrl,
            success: function success(data) {
                updateMiniCart(JSON.parse(data));
            }
        });
    };

    // Private function that updates the mini cart
    // in the nav on every page.
    var updateMiniCart = function updateMiniCart(data) {
        cartItems = data.LineItems;
        // Update the count first
        $(".cart-nav__itemcount").text(cartItems.length);

        if (cartItems.length > 0) {
            // set the html for the desktop mini-cart
            var html = "<div class=\"mini-cart\">\n                <h5 class=\"h4 grad-1\">Recently added to your quote...</h5>\n                <div class =\"mini-cart__line-items\">";
            cartItems.forEach(function (item) {
                html += "<div class=\"mini-cart__line-item\">\n                            " + item.Quantity + "x <a href=\"" + item.Product.Url + "\">" + item.Product.Name + "</a> <span class=\"price\">£" + item.SubTotal.toFixed(2) + "</span>\n                        </div>";
            });
            html += "</div>\n                <div class=\"mini-cart__total text-right\">\n                    <span class =\"mini-cart__other-items\">(<a href=\"/cart/\">" + cartItems.length + " " + (cartItems.length > 1 ? "items" : "item") + " in quote</a>) </span> <strong>Subtotal</strong> £" + data.SubTotal.toFixed(2) + "\n                </div>\n                <a href=\"/cart/\" class=\"btn btn--sml btn--block\">Send/edit quote</a>\n            </div>";
            $(".mini-cart").replaceWith($(html));

            // mobile mini cart
            $(".mini-cart-mob").addClass("has-items");
            $(".mini-cart-mob__qty").text(cartItems.length + (cartItems.length > 1 ? " items" : " item"));
            $(".mini-cart-mob__total").text("£" + data.SubTotal.toFixed(2));
        }
    };

    // Private function to show success modal for
    // product added to cart.
    var showSuccessModal = function showSuccessModal(data) {
        var html = "<div class=\"modal-container\">\n                <div class=\"modal\">\n                    <h3><strong>" + data.Product.Name + "</strong> x" + data.Quantity + " has been added to your quote!</h3>\n                    <a href=\"#\" class=\"btn m-btm-1 continueShopping\">Continue shopping</a> <a href=\"/cart/\" class=\"btn m-btm-1\">Go to quote</a>\n                </div>\n            </div>";
        $(html).appendTo(document.body);
    };

    // Private function, called when product has
    // successfully been addedto users cart.
    var addedToCart = function addedToCart(data) {
        // Clear out the qty values
        $(".qty").val("");
        // Get cart items to update the mini cart!
        ShoppingCart.getCartItems();
        // show success modal
        showSuccessModal(data.AddedItem);
    };

    // Public function to add product to cart
    // using it's Id and quantity.
    var addToCart = function addToCart(prodId, qty, $btn) {
        $.ajax({
            type: "POST",
            url: addToCartUrl,
            data: { id: prodId, qty: qty },
            success: function success(data) {
                // Perform logic on add to cart success
                addedToCart(JSON.parse(data));
                // Re-enable the add to quote button
                $btn.prop('disabled', false).html('<svg role="presentation" class="icon icon--16"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#cart"></use></svg> Add to quote');
            }
        });
    };

    return {
        addToCart: addToCart,
        getCartItems: getCartItems
    };
}();

// Make a call to getCartItems on every page load
ShoppingCart.getCartItems();

// Hook up listeners
$(".addToCartForm").submit(function (e) {
    e.preventDefault();
    ShoppingCart.addToCart($(this).find('.id').val(), $(this).find('.qty').val(), $(this).find('[type="submit"]'));
});

// Remove modal
$("body").on("click", ".modal-container, .continueShopping", function (e) {
    e.stopPropagation();
    $(".modal-container").fadeOut(400, function () {
        $(this).remove();
    });
});

// Sync prod qty changes to hidden inputs for update form
$(".prod-qty").on("change", function () {
    var prodId = $(this).attr("data-prod-id");
    var qty = $(this).val();
    $("[data-prod-id=" + prodId + "]").val(qty);
    //alert(prodId);
});
"use strict";

jQuery.validator.methods.date = function (value, element) {
    var d = value.split("/");
    return this.optional(element) || !/Invalid|NaN/.test(new Date(/chrom(e|ium)/.test(navigator.userAgent.toLowerCase()) ? d[1] + "/" + d[0] + "/" + d[2] : value));
};
'use strict';

var picker = new Pikaday({
    field: $('#Event_Date')[0],
    format: 'DD/MM/YYYY',
    minDate: new Date()
});
'use strict';

var loading_html = '<svg role="presentation" class="icon icon--16"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#loading"></use></svg>';

// Prevent multiple form submissions
$(document).on('submit', 'form', function () {
    var buttons = $(this).find('[type="submit"]');
    if ($(this).valid()) {
        buttons.each(function (btn) {
            $(buttons[btn]).prop('disabled', true);
            $(buttons[btn]).html(loading_html);
        });
    } else {
        buttons.each(function (btn) {
            $(buttons[btn]).prop('disabled', false);
        });
    }
});

// remove the fecking annoying date validation that doesn't work
$("#Event_Date").removeAttr("data-val-date");
"use strict";

/*
var scrollTop = $(window).scrollTop();

function setNavClass(scrollTop) {
    if (scrollTop > 0) {
        $("header.header").addClass("anchored");
    } else {
        $("header.header").removeClass("anchored");
    }
}

setNavClass(scrollTop);

window.onscroll = function () {
    scrollTop = $(window).scrollTop();
    setNavClass(scrollTop);
};
*/
$("#menuToggle input[type='checkbox']").change(function () {
    if ($(this).is(":checked")) $("nav.nav, .nav-container, header.header").addClass("open");else $("nav.nav, .nav-container, header.header").removeClass("open");
});

$("nav a .expand").on("click", function () {
    // Toggle the open class on the lvl2 nav and the svg icon
    $(this).parent().next(".nav__lvl2, .nav__lvl3").toggleClass("open");
    $(this).toggleClass("open");
    return false;
});
"use strict";

var homeSlider = $(".hero__slides").lightSlider({
    item: 1,
    loop: true,
    slideMove: 1,
    mode: 'fade',
    speed: 1000,
    controls: false
});

var productsSlide = $(".product-slider").lightSlider({
    item: 3,
    loop: true,
    slideMove: 1,
    speed: 1000,
    controls: false,
    auto: true,
    pauseOnHover: true,
    pause: 3800,
    adaptiveHeight: true,
    responsive: [{
        breakpoint: 1180,
        settings: {
            item: 2
        }
    }, {
        breakpoint: 979,
        settings: {
            item: 3
        }
    }, {
        breakpoint: 768,
        settings: {
            item: 2
        }
    }, {
        breakpoint: 560,
        settings: {
            item: 1
        }
    }]
});